const domain = {
  conn: window.location.protocol.split(":")[0],
  host: window.location.hostname,
  url: "",
  // 部屬用
  outPort: "",
  // 本地用
  port: ":5555",
};

// 依照domain判斷api請求標頭
switch (domain.host) {
  // case '172.16.3.30':
  //   // 內網
  //   domain.url = `${domain.host}${domain.inPort}`
  //   break;
  default:
    // 外網
    domain.url = `${domain.host}${domain.outPort}`;
}

const isLocal = domain.host === "localhost" || domain.url === "127.0.0.1";

const apiBasic = {
  conn: domain.conn,
  url: isLocal ? domain.host + domain.port : `${domain.url}`,
};

export default apiBasic;
