import React, { useState, useEffect, useContext } from "react";
import { message, Modal, Input, Button } from "antd";
import Title from "../../../components/Custom/Title";
import styled from "styled-components";
import {
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
// import MySubtitleH4 from "../../../components/Custom/SubTitleH4";
import { MySubtitleH4 } from "../../../components/Custom/MySubtitleH4";
import OperateArea from "../../../components/Custom/OperateArea";

import { DataContext } from "../index";
import StoreContext from "../../../components/Context/StoreContext";

import CustomTable from "../../../components/antdCustom/CustomTable";
import CustomModal from "../../../components/antdCustom/CustomModal";

import useAPI from "../../../hooks/useAPI";
import { getTranslateHd, deleteTranslateHd, generateTranslateFile } from '../../../service/api/publicAPI';
import { timeCheckAndFormat } from '../../../util/dateFormat';

import { systemMap } from '../../../util/constants'

import ModalContainer from "./ModalContainer";
import Light from '../Light';

message.config({
  duration: 1.5,
});

const Wrapper = styled.div`
  
  .header-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .flex-end {

  }

  // ipad air(橫式)
  @media screen and (max-width: 1180px) {
  }

  // ipad mini(橫式)
  @media screen and (max-width: 1024px) {
  }

  // ipad air(直式)
  @media screen and (max-width: 820px) {
    .header-container {
      justify-content: flex-end;
    }
  }

  // ipad mini(直式)
  @media screen and (max-width: 768px) {
    .header-container {
      justify-content: flex-end;
    }
  }
`;

const HD = () => {
  // const call_getTranslateHd = useAPI(getTranslateHd);
  const call_deleteTranslateHd = useAPI(deleteTranslateHd);
  const call_generateTranslateFile = useAPI(generateTranslateFile);

  const innerWidth = window.innerWidth;
  const [scrollY, setScrollY] = useState("");
  const [s_showModal, set_s_showModal] = useState(false);
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);

  // const [s_searchValue, set_s_searchValue] = useState({
  //   searchValue:"",
  // });

  const { call_getTranslateHd, c_searchValue, set_c_searchValue, c_lightData, set_c_lightData } = useContext(DataContext);

  const { storeData } = useContext(StoreContext)

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10,
  });

  // 搜尋翻譯資料
  const handleSearch = (type, value) => {
    console.log(value);
    let tmp = {}

    // if(type === 'time') {
    //   tmp = { ...c_searchValue, 'startDate': value[0], 'endDate': value[1] };
    // } else {
    tmp = { ...c_searchValue, [type]: value };
    // }
    
    set_c_searchValue(tmp);
    setPageInfo({...pageInfo,currentPage:1})
    set_c_lightData({});
  };

  // 新增翻譯資料
  const createTranslate = () => {
    set_s_showModal(true);
  };

  useEffect(() => {
    if(call_generateTranslateFile.status === 'suc') {
      message.success(call_generateTranslateFile.msg);
    }else if(call_generateTranslateFile.status === 'err') {
      message.error(call_generateTranslateFile.msg);
    }
  }, [call_generateTranslateFile.status]); 

  // 產生翻譯檔案
  const handleGenerateFile = () => {
    Modal.confirm({
      title: "翻譯檔案產生確認",
      icon: <ExclamationCircleOutlined style={{ color: "blue" }} />,
      content: "請確認要產生的翻譯檔案無誤，再進行產生",
      okText: "確定",
      onOk: () => {
        call_generateTranslateFile.request();
      },
      cancelText: "取消",
    });
  };

  // 編輯翻譯資料
  const handleEdit = (rowData) => {
    set_s_editData(rowData);
    set_s_showModal(true);
  };

  // 刪除翻譯資料
  const handleDelete = (rowData) => {
    Modal.confirm({
      title: "翻譯資料刪除確認",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "請先確認要刪除的翻譯資料無誤，再進行刪除",
      okText: "確定",
      onOk:  () => {
        call_deleteTranslateHd.request(rowData);
        set_c_lightData({});
      },
      cancelText: "取消",
    });
  };

  const tableHeaders = [
    {
      title: "燈號",
      dataIndex: "situF",
      align: "center",
      render: (text) => <Light color={text} size="16px" />,
    },
    {
      title: "分頁ID",
      dataIndex: "pageID",
      align: "center",
      render:(text)=><div className='flex-start'>{text}</div>
    },
    {
      title: "分頁名稱",
      dataIndex: "pageNM",
      align: "center",
      render:(text)=><div className='flex-start'>{text}</div>
    },
    {
      title: "所屬系統",
      dataIndex: "sys",
      align: "center",
      render:(text)=>`${text}-${systemMap[text]}`,
      sorter: (a, b) => a.sys.localeCompare(b.sys),
    },
    {
      title: "備註",
      dataIndex: "note",
      align: "center",
      render:(text)=><div className='flex-start'>{text}</div>
    },
    {
      title: "更新人員",
      dataIndex: "updtID",
      align: "center",
    },
    {
      title: "更新時間",
      dataIndex: "updtT",
      align: "center",
      render:(text) => timeCheckAndFormat(text),
    },
    {
      title: "建立人員",
      dataIndex: "createID",
      align: "center",
    },
    {
      title: "建立時間",
      dataIndex: "createT",
      align: "center",
      render:(text) => timeCheckAndFormat(text),
    },
    {
      title: "操作",
      dataIndex: "render",
      // width: innerWidth <= 1440 ? `${50}px` : `${70}px`,
      align: "center",
      render: (text, rowData, index) => (
        <OperateArea
          onEditClick={() => handleEdit(rowData)}
          onDeleteClick={() => handleDelete(rowData)}
          editDisabled={!storeData.superman} // 不是superman不能編輯
          deleteDisabled={!storeData.superman} // 不是superman不能刪除
        />
      ),
    },
  ];

  // 自動調整寬度
  const tableColumns = tableHeaders.map((item)=>{
    item.width = `${parseInt(100/(tableHeaders.length))}%`

    return item
  })

  //取得表頭資料
  useEffect(() => {
    if (!s_showModal){
      call_getTranslateHd.request(c_searchValue)
    }
  }, [c_searchValue,s_showModal]);

  useEffect(() => {
    if(call_getTranslateHd.status === 'suc') {
      set_s_tableData(call_getTranslateHd.data)
    }
    if(call_getTranslateHd.status === 'err') {
      message.error(call_getTranslateHd.msg);
    }
  }, [call_getTranslateHd.status]);

  useEffect(() => {
    if(call_deleteTranslateHd.status === 'suc') {
      message.success(call_deleteTranslateHd.msg);
    }
    if(call_deleteTranslateHd.status === 'err') {
      message.error(call_deleteTranslateHd.msg);
    }
    call_getTranslateHd.request({...c_searchValue})
  }, [call_deleteTranslateHd.status]);

  // 表身有資料、RWD 時計算高度
  useEffect(() => {
    const ROWHEIGHT = 55;

    // 一般螢幕尺寸
    if (innerWidth >= 1920) {
      // 沒有表身資料
      if (Object.keys(c_lightData).length === 0) {
        setScrollY(ROWHEIGHT * 11);
      } else {
        setScrollY(ROWHEIGHT * 5);
      }
    }
    // macbook 尺寸
    else if (innerWidth >= 1440) {
      // alert(innerWidth)
      // 沒有表身資料
      if (Object.keys(c_lightData).length === 0) {
        setScrollY(ROWHEIGHT * 12);
      } else {
        setScrollY(ROWHEIGHT * 5);
      }
    } else {
      if (Object.keys(c_lightData).length === 0) {
        setScrollY(ROWHEIGHT * 8);
      } else {
        setScrollY(ROWHEIGHT * 4);
      }
    }
  }, [innerWidth, c_lightData]);

  return (
    <Wrapper>
      <Title title="翻譯資料管理" >
        <div className="flex-end">
          <Input.Search
            size="large"
            placeholder={"請輸入欲查詢的翻譯資料"}
            onSearch={(value) => handleSearch("searchValue", value)}
            allowClear
            style={{ width: '300px' }}
            enterButton
          />
          <Button
            onClick={createTranslate}
            type="primary"
            style={{ borderRadius: "5px", marginLeft: "15px" }}
            icon={<PlusOutlined />}
            size="large"
            disabled={!storeData.superman} // 不是superman不能新增
          >
            新增
          </Button>
          <Button
            onClick={handleGenerateFile}
            type="primary"
            style={{ borderRadius: "5px", marginLeft: "15px" }}
            size="large"
            disabled={!storeData.superman} // 不是superman不能產生翻譯檔
            loading={call_generateTranslateFile.status === 'load'}
          >
            產生翻譯檔
          </Button>
        </div>
      </Title>
      <CustomTable
        size={innerWidth <= 1440 ? "16px" : null} // 表頭字體大小
        scroll={{ x: "max-content", y: scrollY }}
        loading={call_getTranslateHd.status === 'load'}
        columns={tableColumns}
        dataSource={s_tableData}
        rowClassName={(record, index) => {
          const rowKey = record.pageID+record.sys;
          const dataKey = c_lightData.pageID+c_lightData.sys;
          const clickRowStyle = rowKey === dataKey ? "clickRowStyle" : "";
          return index % 2 === 1
            ? `light ${clickRowStyle}`
            : `dark ${clickRowStyle}`;
          // return rowKey === dataKey ? "clickRowStyle" : "";
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              const rowKey = record.pageID+record.sys;
              const dataKey = c_lightData.pageID+c_lightData.sys;
              if (rowKey === dataKey) {
                set_c_lightData({});
              } else {
                set_c_lightData(record);
              }
            },
          };
        }}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size,
            });
            set_c_lightData({})
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />

      {s_showModal ? (
        <CustomModal
          width="60%"
          title={
            Object.keys(s_editData).length === 0 ? "新增翻譯資料" : "編輯翻譯資料"
          }
          visible={s_showModal}
          onCancel={() => set_s_showModal(false)}
        >
          <ModalContainer
            set_s_showModal={set_s_showModal}
            s_editData={s_editData}
            set_s_editData={set_s_editData}
          />
        </CustomModal>
      ) : null}
    </Wrapper>
  );
};

export default HD;
