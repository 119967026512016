import { instance } from "../handlerAPI";
import apiBasic from "../APIBasic";

export const login = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/login`, data);
};

export const logout = () => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/logout`);
};

export const getUserPerm = () => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/getUserPerm`);
};

export const getTranslateHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getTranslateHd`, { params: _params });
};

export const addTranslateHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addTranslateHd`, data);
};

export const updateTranslateHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateTranslateHd`, data);
};

export const deleteTranslateHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteTranslateHd`, data);
};

export const getTranslateTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getTranslateTb`, { params: _params });
};

export const addTranslateTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addTranslateTb`, data);
};

export const updateTranslateTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateTranslateTb`, data);
};

export const deleteTranslateTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteTranslateTb`, data);
};

export const getAccount = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAccount`, { params: _params });
};

export const addAccount = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addAccount`, data);
};

export const updateAccount = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateAccount`, data);
};

export const deleteAccount = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteAccount`, data);
};

export const generateTranslateFile = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/generateTranslateFile`, data);
};
